import React from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import withStyles from "@material-ui/core/styles/withStyles"
import ChartistGraph from "react-chartist"
import Chartist from "chartist"
import { connect } from "react-redux"
import AccessTime from "@material-ui/icons/AccessTime"
import Error from "@material-ui/icons/Error"
import dashboardStyle from "../../assets/css/layout/dashboardStyle"
import GridContainer from "../../components/Grid/GridContainer.jsx"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardFooter from "../../components/Card/CardFooter"
import CardIcon from "../../components/Card/CardIcon"
import Book from "@material-ui/icons/Book"
import Group from "@material-ui/icons/Group"
import FileCopy from "@material-ui/icons/FileCopy"
import NewReleases from "@material-ui/icons/NewReleases"
import Class from "@material-ui/icons/Class"
import SwapHoriz from "@material-ui/icons/SwapHoriz"
import AttachMoney from "@material-ui/icons/AttachMoney"
import Restore from "@material-ui/icons/Restore"
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import {
  cadastroDividaTabChanged, cadastrosTabChanged, cadastrosAlvaraTabChanged,
  movimentosITBITabChanged, dividaRelatoriosTabChanged, damAvulsoTabChanged,
  movimentosTabChanged
} from '../../actions'
import CardBody from "../../components/Card/CardBody.jsx"
import { arrecadacaoPorMes, arrecadacaoPorMesIPTU, arrecadacaoPorMesAlvara, arrecadacaoPorMesDamAvulso, alvarasInYear, carnesDeIPTUInYear, damsAvulsosInYear } from "../../util/dashboard"
import Home from "@material-ui/icons/Home"
import { Typography, Button } from "@material-ui/core"
import LoadingContent from "../../components/LoadingContent"
import { bindActionCreators } from "redux"
import LocationCity from "@material-ui/icons/LocationCity"
export class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      errorCountAlvara: false,
      errorCountIPTU: false,
      errorCountDamAvulso: false,
      arrecadacaoDamAvulsoMensalError: false,
      arrecadacaoIPTUMensalError: false,
      arrecadacaoAlvaraMensalError: false,
      arrecadacaoMensalError: false,
    }
    this.resizeFunction = this.resizeFunction.bind(this)
    this.getArrecadacaoMensal = this.getArrecadacaoMensal.bind(this)
    this.getArrecadacaoMensalIPTU = this.getArrecadacaoMensalIPTU.bind(this)
    this.getArrecadacaoMensalAlvara = this.getArrecadacaoMensalAlvara.bind(this)
    this.getArrecadacaoMensalDamAvulso = this.getArrecadacaoMensalDamAvulso.bind(this)
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  getRoute() {
    return this.props.location.pathname !== "/maps"
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction)
  }
  componentDidMount() {
    this.getArrecadacaoMensal()
    this.getIPTUSInYear()
    this.getAlvarasInYear()
    this.getDamsAvulsosInYear()
    this.getArrecadacaoMensalIPTU()
    this.getArrecadacaoMensalAlvara()
    this.getArrecadacaoMensalDamAvulso()
  }

  getArrecadacaoMensal() {
    arrecadacaoPorMes((error, data) => {
      if (error) {
        this.setState({
          arrecadacaoMensalError: true
        })
        console.log(error)
      } else {
        var delays = 80,
          durations = 500
        let labels = Object.keys(data.valores)
        let series = [Object.keys(data.valores).map((item) => {
          return data.valores[item]
        })]
        const arrecadacaoDataSet = {
          data: {
            labels: labels,
            series: series
          },
          options: {
            lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0
            }),
            low: 0,
            high: data.max,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 5
            }
          },
          animation: {
            draw: function (data) {
              if (data.type === "line" || data.type === "area") {
                data.element.animate({
                  d: {
                    begin: 600,
                    dur: 700,
                    from: data.path
                      .clone()
                      .scale(1, 0)
                      .translate(0, data.chartRect.height())
                      .stringify(),
                    to: data.path.clone().stringify(),
                    easing: Chartist.Svg.Easing.easeOutQuint
                  }
                })
              } else if (data.type === "point") {
                data.element.animate({
                  opacity: {
                    begin: (data.index + 1) * delays,
                    dur: durations,
                    from: 0,
                    to: 1,
                    easing: "ease"
                  }
                })
              }
            }
          }
        }
        this.setState({ arrecadacaoDataSet: arrecadacaoDataSet })
      }
    })
  }


  getAlvarasInYear() {
    alvarasInYear((error, data) => {
      if (error) {
        this.setState({
          errorCountAlvara: true
        })
        console.log(error)
      } else {
        this.setState({ alvaraCount: data.count })
      }
    })
  }

  getDamsAvulsosInYear() {
    damsAvulsosInYear((error, data) => {
      if (error) {
        this.setState({
          errorCountDamAvulso: true
        })
      } else {
        this.setState({ damsAvulsosCount: data.count })
      }
    })
  }

  getIPTUSInYear() {
    carnesDeIPTUInYear((error, data) => {
      if (error) {
        this.setState({
          errorCountIPTU: true
        })
      } else {
        this.setState({ iptuCount: data.count })
      }
    })
  }

  getArrecadacaoMensalIPTU() {
    arrecadacaoPorMesIPTU((error, data) => {
      if (error) {
        this.setState({
          arrecadacaoIPTUMensalError: true
        })
      } else {
        var delays = 80,
          durations = 500
        let labels = Object.keys(data.valores).map((item) => item.substring(0, 3).toUpperCase())
        let series = [Object.keys(data.valores).map((item) => {
          return data.valores[item]
        })]
        const arrecadacaoDataSet = {
          data: {
            labels: labels,
            series: series
          },
          options: {
            axisX: {
              showGrid: true
            },
            low: 0,
            high: data.max,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 5
            }
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0]
                  }
                }
              }
            ]
          ],
          animation: {
            draw: function (data) {
              if (data.type === "bar") {
                data.element.animate({
                  opacity: {
                    begin: (data.index + 1) * delays,
                    dur: durations,
                    from: 0,
                    to: 1,
                    easing: "ease"
                  }
                })
              }
            }
          }
        }
        this.setState({ arrecadacaoPorMesIPTU: arrecadacaoDataSet })
      }
    })
  }
  getArrecadacaoMensalAlvara() {
    arrecadacaoPorMesAlvara((error, data) => {
      if (error) {
        this.setState({
          arrecadacaoAlvaraMensalError: true
        })
        console.log(error)
      } else {
        var delays = 80,
          durations = 500
        let labels = Object.keys(data.valores).map((item) => item.substring(0, 3).toUpperCase())
        let series = [Object.keys(data.valores).map((item) => {
          return data.valores[item]
        })]
        const arrecadacaoDataSet = {
          data: {
            labels: labels,
            series: series
          },
          options: {
            axisX: {
              showGrid: true
            },
            low: 0,
            high: data.max,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 5
            }
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {
                seriesBarDistance: 7,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0]
                  }
                }
              }
            ]
          ],
          animation: {
            draw: function (data) {
              if (data.type === "bar") {
                data.element.animate({
                  opacity: {
                    begin: (data.index + 1) * delays,
                    dur: durations,
                    from: 0,
                    to: 1,
                    easing: "ease"
                  }
                })
              }
            }
          }
        }
        this.setState({ arrecadacaoAlvaraDataSet: arrecadacaoDataSet })
      }
    })
  }

  getArrecadacaoMensalDamAvulso() {
    arrecadacaoPorMesDamAvulso((error, data) => {
      if (error) {
        this.setState({ arrecadacaoDamAvulsoMensalError: true })
      } else {
        var delays = 80,
          durations = 500
        let labels = Object.keys(data.valores).map((item) => item.substring(0, 3).toUpperCase())
        let series = [Object.keys(data.valores).map((item) => {
          return data.valores[item]
        })]
        const arrecadacaoDataSet = {
          data: {
            labels: labels,
            series: series
          },
          options: {
            axisX: {
              showGrid: true
            },
            low: 0,
            high: data.max,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 5
            }
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {
                seriesBarDistance: 7,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0]
                  }
                }
              }
            ]
          ],
          animation: {
            draw: function (data) {
              if (data.type === "bar") {
                data.element.animate({
                  opacity: {
                    begin: (data.index + 1) * delays,
                    dur: durations,
                    from: 0,
                    to: 1,
                    easing: "ease"
                  }
                })
              }
            }
          }
        }
        this.setState({ arrecadacaoDamAvulsoDataSet: arrecadacaoDataSet })
      }
    })
  }
  render() {
    const { classes } = this.props
    return (
      <div ref="mainPanel">
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <h2 style={{ marginLeft: 20 }}>Bem vindo, {this.props.userName}</h2>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} style={{ textAlign: 'right' }}>
            <strong>
              SUPORTE <br />
              <PhoneIphoneIcon /> (88) 99660-9707
              </strong>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Typography color="textSecondary" variant="caption" style={{ marginLeft: 20 }} >Atalhos</Typography>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Link to="/contribuintes/cadastro">
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="rose">
                    <Group />
                    <h4 style={{ color: "white" }}>Contribuintes</h4>
                  </CardIcon>
                </Link>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.cadastrosTabChanged(0)
                  this.props.history.push('/iptu/cadastro')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="info">
                    <Home />
                    <h4 style={{ color: "white" }}>Imóveis</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.dividaRelatoriosTabChanged(0)
                  this.props.history.push('/divida/relatorios')
                }}>
                  <CardIcon fullWidth className={" " + classes.cardIconFull + " " + classes.gridItem} color="primary">
                    <AttachMoney />
                    <h4 style={{ color: "white" }}>Demonstrativo de Dívida Ativa</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.damAvulsoTabChanged(0)
                  this.props.history.push('/damavulso/cadastro')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="warning">
                    <FileCopy />
                    <h4 style={{ color: "white" }}>DAM Avulso</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.cadastroDividaTabChanged(3)
                  this.props.history.push('/divida/refinanciamento/create')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="primary">
                    <SwapHoriz />
                    <h4 style={{ color: "white" }}>Adicionar novo Refinanciamento</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.cadastroDividaTabChanged(3)
                  this.props.history.push('/divida/pagamento/create')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="success">
                    <SwapHoriz />
                    <h4 style={{ color: "white" }}>Adicionar novo Pagamento de dívida</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.movimentosTabChanged(0)
                  this.props.history.push('/iptu/movimentos')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="rose">
                    <NewReleases />
                    <h4 style={{ color: "white" }}>Lançamentos de IPTU</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.movimentosITBITabChanged(0)
                  this.props.history.push('/itbi/movimentos')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <SwapHoriz />
                    <h4 style={{ color: "white" }}>ITBI</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.cadastrosAlvaraTabChanged(0)
                  this.props.history.push('/alvara/cadastro')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="success">
                    <Class />
                    <h4 style={{ color: "white" }}>Emissão de alvará</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.cadastroDividaTabChanged(0)
                  this.props.history.push('/divida/cadastro')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="info">
                    <Class />
                    <h4 style={{ color: "white" }}>Dívida ativa</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.history.push('/projeto')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Class />
                    <h4 style={{ color: "white" }}>Construção civil</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>

          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Button fullWidth onClick={(e) => {
                  this.props.history.push('/ocupacao')
                }}>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="info">
                    <LocationCity />
                    <h4 style={{ color: "white" }}>Ocupação de Imóvel</h4>
                  </CardIcon>
                </Button>
              </CardHeader>
            </Card>
          </GridItem>

        </GridContainer>
        <GridContainer>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            {this.state.errorCountAlvara ? (
              <Card>
                <CardHeader className={classes.gridItem} icon stats>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Error />
                    <Typography variant="h4" style={{ color: "white" }}>Erro de conexão</Typography>
                  </CardIcon>
                </CardHeader>
              </Card>
            ) : (
                <LoadingContent isLoading={this.state.alvaraCount === undefined}>
                  <Card>
                    <CardHeader stats icon>
                      <CardIcon color="warning">
                        <Book />
                      </CardIcon>
                      <Typography variant="caption" color="success" style={{ marginTop: '2%' }}>Alvarás emitidos em {new Date().getFullYear()}</Typography>
                      <h4 className={classes.cardTitle} style={{ marginTop: '2%' }}>
                        {this.state.alvaraCount} <small>Dams emitidos</small>
                      </h4>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Link to="/alvara/cadastro">
                          Ir para a listagem de alvará
                  </Link>
                      </div>
                    </CardFooter>
                  </Card>
                </LoadingContent>)
            }

          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            {this.state.errorCountIPTU ? (
              <Card>
                <CardHeader className={classes.gridItem} icon stats>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Error />
                    <h4 style={{ color: "white" }}>Erro de conexão</h4>
                  </CardIcon>
                </CardHeader>
              </Card>
            ) : (
                <LoadingContent isLoading={this.state.iptuCount === undefined}>
                  <Card>
                    <CardHeader stats icon>
                      <CardIcon color="success">
                        <Home />
                      </CardIcon>
                      <Typography variant="caption" color="success" style={{ marginTop: '2%' }}>Carnês de IPTU lançados em {new Date().getFullYear()}</Typography>
                      <h4 className={classes.cardTitle} style={{ marginTop: '2%' }}>
                        {this.state.iptuCount} <small>Carnês emitidos</small>
                      </h4>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Link to="/iptu/cadastro">
                          Ir para a listagem de imóveis
                  </Link>
                      </div>
                    </CardFooter>
                  </Card>
                </LoadingContent>)}
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            {this.state.errorCountDamAvulso ? (
              <Card>
                <CardHeader className={classes.gridItem} icon stats>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Error />
                    <h4 style={{ color: "white" }}>Erro de conexão</h4>
                  </CardIcon>
                </CardHeader>
              </Card>
            ) : (
                <LoadingContent isLoading={this.state.damsAvulsosCount === undefined}>
                  <Card >
                    <CardHeader stats icon>
                      <CardIcon color="primary">
                        <Home />
                      </CardIcon>
                      <Typography variant="caption" color="success" style={{ marginTop: '2%' }}>Dams avulsos emitidos em {new Date().getFullYear()}</Typography>
                      <h4 className={classes.cardTitle} style={{ marginTop: '2%' }}>
                        {this.state.damsAvulsosCount} <small>Dams emitidos</small>
                      </h4>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Link to="/damavulso/cadastro">
                          Ir para a listagem de DAM's avulsos
                  </Link>
                      </div>
                    </CardFooter>
                  </Card>
                </LoadingContent>
              )}
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={3}>
            <Card>
              <CardHeader className={classes.gridItem} icon stats>
                <Link to="/baixaautomatica">
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="primary">
                    <Restore />
                    <h4 style={{ color: "white" }}>Realizar baixa automática</h4>
                  </CardIcon>
                </Link>
              </CardHeader>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={12}>
            {this.state.arrecadacaoMensalError ? (
              <Card>
                <CardHeader className={classes.gridItem} icon stats>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Error />
                    <h4 style={{ color: "white" }}>Erro de conexão</h4>
                  </CardIcon>
                </CardHeader>
              </Card>
            ) : (
                <LoadingContent isLoading={this.state.arrecadacaoDataSet === undefined}>
                  {this.state.arrecadacaoDataSet !== undefined && <Card chart>
                    <CardHeader color="info">
                      <ChartistGraph
                        className="ct-chart"
                        data={this.state.arrecadacaoDataSet.data}
                        type="Line"
                        options={this.state.arrecadacaoDataSet.options}
                        listener={this.state.arrecadacaoDataSet.animation}
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardTitle}>Arrecadação mensal</h4>
                    </CardBody>
                    <CardFooter chart>
                      <div className={classes.stats}>
                        <AccessTime /> Valores referentes aos últimos 12 meses
                </div>
                    </CardFooter>
                  </Card>}

                </LoadingContent>)}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={4}>
            {this.state.arrecadacaoAlvaraMensalError ? (
              <Card>
                <CardHeader className={classes.gridItem} icon stats>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Error />
                    <h4 style={{ color: "white" }}>Erro de conexão</h4>
                  </CardIcon>
                </CardHeader>
              </Card>
            ) : (
                <LoadingContent isLoading={this.state.arrecadacaoAlvaraDataSet === undefined}>
                  {this.state.arrecadacaoAlvaraDataSet !== undefined && <Card chart>
                    <CardHeader color="success">
                      <ChartistGraph
                        className="ct-chart"
                        data={this.state.arrecadacaoAlvaraDataSet.data}
                        type="Bar"
                        options={this.state.arrecadacaoAlvaraDataSet.options}
                        responsiveOptions={this.state.arrecadacaoAlvaraDataSet.responsiveOptions}
                        listener={this.state.arrecadacaoAlvaraDataSet.animation}
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardTitle}>Valores arrecadados com álvará</h4>
                    </CardBody>
                    <CardFooter chart>
                      <div className={classes.stats}>
                        <AccessTime /> Valores referentes aos últimos 12 meses
                </div>
                    </CardFooter>
                  </Card>
                  }
                </LoadingContent>
              )}
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={4}>
            {this.state.arrecadacaoIPTUMensalError ? (
              <Card>
                <CardHeader className={classes.gridItem} icon stats>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Error />
                    <h4 style={{ color: "white" }}>Erro de conexão</h4>
                  </CardIcon>
                </CardHeader>
              </Card>
            ) : (
                <LoadingContent isLoading={this.state.arrecadacaoPorMesIPTU === undefined}>
                  {this.state.arrecadacaoPorMesIPTU !== undefined && <Card chart>
                    <CardHeader color="primary">
                      <ChartistGraph
                        className="ct-chart"
                        data={this.state.arrecadacaoPorMesIPTU.data}
                        type="Bar"
                        options={this.state.arrecadacaoPorMesIPTU.options}
                        responsiveOptions={this.state.arrecadacaoPorMesIPTU.responsiveOptions}
                        listener={this.state.arrecadacaoPorMesIPTU.animation}
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardTitle}>Valores arrecadados com IPTU</h4>
                    </CardBody>
                    <CardFooter chart>
                      <div className={classes.stats}>
                        <AccessTime /> Valores referentes aos últimos 12 meses
                </div>
                    </CardFooter>
                  </Card>
                  }
                </LoadingContent>
              )}
          </GridItem>
          <GridItem classes={{ grid: classes.gridItem }} xs={12} sm={12} md={4}>
            {this.state.arrecadacaoDamAvulsoMensalError ? (
              <Card>
                <CardHeader className={classes.gridItem} icon stats>
                  <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="danger">
                    <Error />
                    <h4 style={{ color: "white" }}>Erro de conexão</h4>
                  </CardIcon>
                </CardHeader>
              </Card>
            ) : (
                <LoadingContent isLoading={this.state.arrecadacaoDamAvulsoDataSet === undefined}>
                  {this.state.arrecadacaoDamAvulsoDataSet !== undefined && <Card chart>
                    <CardHeader color="warning">
                      <ChartistGraph
                        className="ct-chart"
                        data={this.state.arrecadacaoDamAvulsoDataSet.data}
                        type="Bar"
                        options={this.state.arrecadacaoDamAvulsoDataSet.options}
                        responsiveOptions={this.state.arrecadacaoDamAvulsoDataSet.responsiveOptions}
                        listener={this.state.arrecadacaoDamAvulsoDataSet.animation}
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardTitle}>Valores arrecadados com DAM Avulso</h4>
                    </CardBody>
                    <CardFooter chart>
                      <div className={classes.stats}>
                        <AccessTime /> Valores referentes aos últimos 12 meses
                </div>
                    </CardFooter>
                  </Card>
                  }
                </LoadingContent>
              )}
          </GridItem>
        </GridContainer >

      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    userName: state.user.nome
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cadastroDividaTabChanged, cadastrosTabChanged, cadastrosAlvaraTabChanged,
    movimentosITBITabChanged, dividaRelatoriosTabChanged, damAvulsoTabChanged,
    movimentosTabChanged
  }, dispatch)
}
Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}
Dashboard = withRouter(Dashboard)
export default withStyles(dashboardStyle)(Dashboard)