import React from "react"

import Login from "../layout/Login/Login"
import { Switch, Route, Redirect } from "react-router-dom"
import Contribuintes from "../views/Contribuintes/Contribuinte"

import Dashboard from "../layout/Dashboard/Dashboard"
import NotFound from "../views/NotFound"
import LayoutWrapper from "../components/Layout/LayoutWrapper"
import Iptu from "../views/Iptu/Iptu"
import ContribuinteForm from "../views/Contribuintes/ContribuinteForm"
import SecaoLogradouroForm from "../views/Iptu/Secao/SecaoLogradouroForm"
import MotivosIptuForm from "../views/Iptu/Motivoiptu/MotivosIptuForm"
import ParametrosForm from "../views/Iptu/Parametros/ParametrosForm"
import ImoveisForm from "../views/Iptu/Imoveis/ImoveisForm"
import MovimentosIptu from "../views/Iptu/Movimento/MovimentosIptu"
import IsencaoIptuForm from "../views/Iptu/Movimento/IsencaoIptuForm"
import LancamentoIptuForm from "../views/Iptu/Movimento/LancamentoIptuForm"
import AlvaraMain from "../views/Alvara/AlvaraMain"
import AlvaraForm from "../views/Alvara/AlvaraForm"
import TaxasForm from "../views/Alvara/TaxasForm"
import MovimentoAlvara from "../views/Alvara/Movimento/MovimentoAlvara"
import ConfigProfile from "../views/ConfigProfile"
import Gerais from "../views/CadastrosGerais/Gerais"
import BaixaAutomatica from "../views/BaixaAutomatica"
import Relatorio from "../views/Iptu/Relatorios/Relatorio"
import Itbi from "../views/Itbi/Itbi"
import AvaliacaoForm from "../views/Itbi/AvaliacaoForm"
import EmissaoForm from "../views/Itbi/EmissaoForm"
import MovimentosGerais from "../views/MovimentosGerais/MovimentosGerais"
import DamAvulsoForm from "../views/DamAvulso/DamAvulsoForm"
import BaixaManual from "../views/BaixasManuais/BaixaManual"
import Projeto from "../views/Projeto/Projeto"
import ProjetoForm from "../views/Projeto/ProjetoForm"
import Ocupacao from "../views/Ocupacao/Ocupacao"
import OcupacaoForm from "../views/Ocupacao/OcupacaoForm"
import RefisCadastro from "../views/Divida/Cadastro/Refis"
import RefisForm from "../views/Divida/Cadastro/RefisForm"
import Processamento from "../views/Divida/Processamento/Processamento"
import Cadastro from "../views/Divida/Cadastro/Cadastro"
import DamAvulsoMain from "../views/DamAvulso/DamAvulsoMain"
import RefinanciamentoForm from "../views/Divida/Cadastro/RefinanciamentoForm"
import RefinanciamentoRestore from "../views/Divida/Cadastro/RefinanciamentoRestore"
import LancamentoLivro from "../views/Divida/Processamento/LancamentoLivro"
import LancamentoCdaForm from "../views/Divida/Processamento/LancamentoCdaForm"
import Notificacao from "../views/Divida/Processamento/Notificacao"
import ValidacaoToken from "../views/ValidacaoToken"
import ValidacaoTokenIptu from "../views/ValidacaoTokenIptu"
import ValidacaoTokenPositiva from "../views/ValidacaoTokenPositiva"
import PagamentoForm from "../views/Divida/Cadastro/PagamentoForm"
import Relatorios from "../views/Divida/Relatorios/Relatorios"
import MovimentoDivida from "../views/Divida/Movimento/MovimentoDivida"
import ITBIRelatorios from "../views/Itbi/Relatorio/ITBIRelatorios"
import RelatorioDam from "../views/DamAvulso/Relatorio/Relatorio"
import RelatorioMain from "../views/Alvara/ Relatorio/RelatorioMain"
import RelatoriosGerais from "../views/RelatoriosGerais/RelatoriosGerais"
import Usuario from "../views/Usuario/Usuario"
import UserCadastro from "../views/Usuario/UserCadastro"
import LancamentoDivida from "../views/Divida/Processamento/LancamentoDivida"

import TemplateImovel from "../templates/ImovelTemplate/ImovelTemplate"

// import Main from "../views/AcessoPublico/Main"
import Protocolos from "../views/Protocolo/Protocolos"
import ProtolocoForm from "../views/Protocolo/ProtolocoForm"
import ParcelaUnicaForm from "../views/Iptu/Movimento/ParcelaUnicaForm"
import Main from "../views/AcessoPublico/Main"
import CND from "../views/AcessoPublico/CND"
import CNIPTU from "../views/AcessoPublico/CNIPTU"
import CPEN from "../views/AcessoPublico/CPEN"
import Alvara from "../views/AcessoPublico/Alvara"

import IptuContribuinte from "../views/AcessoPublico/iptu"
import ValidacaoAlvara from "../views/ValidacaoAlvara"
import BaixaIptuEdit from "../views/Iptu/BaixaIptuEdit"
import BaixaIptuForm from "../views/Iptu/BaixaIptuForm"
import AlvaraBaixasForm from "../views/Alvara/AlvaraBaixasForm"
import AlvaraBaixasEdit from "../views/Alvara/AlvaraBaixasEdit"
import BaixaItbiEdit from "../views/Itbi/BaixaItbiEdit"
import BaixaItbiForm from "../views/Itbi/BaixaItbiForm"
import AvulsoBaixasEdit from "../views/DamAvulso/AvulsoBaixasEdit"
import AvulsoBaixasForm from "../views/DamAvulso/AvulsoBaixasForm"
import BaixaOcupacaoEdit from "../views/Ocupacao/BaixaOcupacaoEdit"
import BaixaOcupacaoForm from "../views/Ocupacao/BaixaOcupacaoForm"
import BaixaPagamentoEdit from "../views/Divida/Cadastro/BaixaPagamentoEdit"
import BaixaPagamentoForm from "../views/Divida/Cadastro/BaixaPagamentoForm"
import BaixaRefisEdit from "../views/Divida/Cadastro/BaixaRefisEdit"
import BaixaRefisForm from "../views/Divida/Cadastro/BaixaRefisForm"
import BaixaProjetoForm from "../views/Projeto/BaixaProjetoForm"
import BaixaProjetoEdit from "../views/Projeto/BaixaProjetoEdit"

function loggedIn() {
  if (!localStorage.token) {
    return false
  }
  return localStorage.token !== "null" && localStorage.token !== ""
}

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { from: props.location }
          }}
        />
      ) : (
          <Component {...props} />
        )
    }
  />
)

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
    }
  />
)
const RedirectToLogin = ({ ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location }
        }}
      />
    )}
  />
)

export class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <LoginRoute exact path="/login" component={Login} />
        <PrivateRoute
          exact
          path="/contribuintes/cadastro"
          component={LayoutWrapper(Contribuintes)}
        />
        <PrivateRoute
          exact
          path="/contribuintes/cadastro/create"
          component={LayoutWrapper(ContribuinteForm)}
        />
        <PrivateRoute
          exact
          path="/contribuintes/cadastro/edit/:id"
          component={LayoutWrapper(ContribuinteForm)}
        />
        <PrivateRoute
          exact
          path="/contribuintes/processamentos"
          component={LayoutWrapper(Contribuintes)}
        />
        <PrivateRoute
          exact
          path="/contribuintes/demonstrativos"
          component={LayoutWrapper(Contribuintes)}
        />
        {/* IPTU */}
        <PrivateRoute
          exact
          path="/iptu/baixasiptu/edit/:id"
          component={LayoutWrapper(BaixaIptuEdit)}
        />
        <PrivateRoute
          exact
          path="/iptu/baixasiptu/create"
          component={LayoutWrapper(BaixaIptuForm)}
        />
        <PrivateRoute
          exact
          path="/iptu/cadastro"
          component={LayoutWrapper(Iptu)}
        />
        <PrivateRoute
          exact
          path="/iptu/processamentos"
          component={LayoutWrapper(Iptu)}
        />
        <PrivateRoute
          exact
          path="/iptu/relatorios"
          component={LayoutWrapper(Relatorio)}
        />
        <PrivateRoute
          exact
          path="/iptu/demonstrativos"
          component={LayoutWrapper(Iptu)}
        />
        <PrivateRoute
          exact
          path="/secoes/create"
          component={LayoutWrapper(SecaoLogradouroForm)}
        />
        <PrivateRoute
          exact
          path="/secoes/edit/:id"
          component={LayoutWrapper(SecaoLogradouroForm)}
        />
        <PrivateRoute
          exact
          path="/motivos/create"
          component={LayoutWrapper(MotivosIptuForm)}
        />
        <PrivateRoute
          exact
          path="/motivos/edit/:id"
          component={LayoutWrapper(MotivosIptuForm)}
        />
        <PrivateRoute
          exact
          path="/parametroscarne/edit/:id"
          component={LayoutWrapper(ParametrosForm)}
        />
        <PrivateRoute
          exact
          path="/parametroscarne/create"
          component={LayoutWrapper(ParametrosForm)}
        />
        <PrivateRoute
          exact
          path="/imoveis/edit/:id"
          component={LayoutWrapper(ImoveisForm)}
        />
        <PrivateRoute
          exact
          path="/imoveis/create"
          component={LayoutWrapper(ImoveisForm)}
        />
        <PrivateRoute
          exact
          path="/iptu/movimentos"
          component={LayoutWrapper(MovimentosIptu)}
        />
        <PrivateRoute
          exact
          path="/lancamento/create"
          component={LayoutWrapper(LancamentoIptuForm)}
        />
        <PrivateRoute
          exact
          path="/isencao/create"
          component={LayoutWrapper(IsencaoIptuForm)}
        />
        <PrivateRoute
          exact
          path="/isencao/edit/:id"
          component={LayoutWrapper(IsencaoIptuForm)}
        />
        <PrivateRoute
          exact
          path="/alvara/cadastro"
          component={LayoutWrapper(AlvaraMain)}
        />
        <PrivateRoute
          exact
          path="/alvara/create"
          component={LayoutWrapper(AlvaraForm)}
        />
        <PrivateRoute
          exact
          path="/alvara/edit/:id"
          component={LayoutWrapper(AlvaraForm)}
        />
        <PrivateRoute
          exact
          path="/alvara/alvarabaixas/create"
          component={LayoutWrapper(AlvaraBaixasForm)}
        />
        <PrivateRoute
          exact
          path="/alvara/alvarabaixas/edit/:id"
          component={LayoutWrapper(AlvaraBaixasEdit)}
        />
        <PrivateRoute
          exact
          path="/taxas/create"
          component={LayoutWrapper(TaxasForm)}
        />
        <PrivateRoute
          exact
          path="/taxas/edit/:id"
          component={LayoutWrapper(TaxasForm)}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={LayoutWrapper(Dashboard)}
        />
        <PrivateRoute
          exact
          path="/alvara/movimentos"
          component={LayoutWrapper(MovimentoAlvara)}
        />
        <PrivateRoute
          exact
          path="/alvara/relatorios"
          component={LayoutWrapper(RelatorioMain)}
        />
        <PrivateRoute
          exact
          path="/lanclivro/create"
          component={LayoutWrapper(LancamentoLivro)}
        />
        <PrivateRoute
          exact
          path="/lanccda/create"
          component={LayoutWrapper(LancamentoCdaForm)}
        />
        <PrivateRoute
          exact
          path="/lancamentodivida/create"
          component={LayoutWrapper(LancamentoDivida)}
        />
        <PrivateRoute
          exact
          path="/configuracao"
          component={LayoutWrapper(ConfigProfile)}
        />
        <PrivateRoute exact path="/gerais" component={LayoutWrapper(Gerais)} />
        <PrivateRoute
          exact
          path="/movimentos"
          component={LayoutWrapper(MovimentosGerais)}
        />
        <PrivateRoute
          exact
          path="/baixaautomatica"
          component={LayoutWrapper(BaixaAutomatica)}
        />
        <PrivateRoute
          exact
          path="/baixamanual"
          component={LayoutWrapper(BaixaManual)}
        />

        <PrivateRoute
          exact
          path="/itbi/movimentos"
          component={LayoutWrapper(Itbi)}
        />
        <PrivateRoute
          exact
          path="/itbi/relatorios"
          component={LayoutWrapper(ITBIRelatorios)}
        />
        <PrivateRoute
          exact
          path="/itbi/baixasitbi/edit/:id"
          component={LayoutWrapper(BaixaItbiEdit)}
        />
        <PrivateRoute
          exact
          path="/itbi/baixasitbi/create"
          component={LayoutWrapper(BaixaItbiForm)}
        />
        <PrivateRoute
          exact
          path="/emissao/create"
          component={LayoutWrapper(EmissaoForm)}
        />
        <PrivateRoute
          exact
          path="/emissao/edit/:id"
          component={LayoutWrapper(EmissaoForm)}
        />
        <PrivateRoute
          exact
          path="/damavulso/cadastro"
          component={LayoutWrapper(DamAvulsoMain)}
        />
        <PrivateRoute
          exact
          path="/damavulso/relatorios"
          component={LayoutWrapper(RelatorioDam)}
        />
        <PrivateRoute
          exact
          path="/damavulso/create"
          component={LayoutWrapper(DamAvulsoForm)}
        />
        <PrivateRoute
          exact
          path="/damavulso/edit/:id"
          component={LayoutWrapper(DamAvulsoForm)}
        />
        <PrivateRoute
          exact
          path="/damavulso/avulsobaixas/edit/:id"
          component={LayoutWrapper(AvulsoBaixasEdit)}
        />
        <PrivateRoute
          exact
          path="/damavulso/avulsobaixas/create"
          component={LayoutWrapper(AvulsoBaixasForm)}
        />
        <PrivateRoute
          exact
          path="/projetos/edit/:id"
          component={LayoutWrapper(ProjetoForm)}
        />
        <PrivateRoute
          exact
          path="/projetos/create"
          component={LayoutWrapper(ProjetoForm)}
        />
        <PrivateRoute
          exact
          path="/projeto"
          component={LayoutWrapper(Projeto)}
        />
        <PrivateRoute
          exact
          path="/projeto/baixasprojeto/edit/:id"
          component={LayoutWrapper(BaixaProjetoEdit)}
        />
        <PrivateRoute
          exact
          path="/projeto/baixasprojeto/create"
          component={LayoutWrapper(BaixaProjetoForm)}
        />
        <PrivateRoute
          exact
          path="/ocupacao"
          component={LayoutWrapper(Ocupacao)}
        />
        <PrivateRoute
          exact
          path="/ocupacao/create"
          component={LayoutWrapper(OcupacaoForm)}
        />
        <PrivateRoute
          exact
          path="/ocupacao/edit/:id"
          component={LayoutWrapper(OcupacaoForm)}
        />
        <PrivateRoute
          exact
          path="/ocupacao/baixasocupacao/edit/:id"
          component={LayoutWrapper(BaixaOcupacaoEdit)}
        />
        <PrivateRoute
          exact
          path="/ocupacao/baixasocupacao/create"
          component={LayoutWrapper(BaixaOcupacaoForm)}
        />
        <PrivateRoute
          exact
          path="/divida/refis"
          component={LayoutWrapper(RefisCadastro)}
        />
        <PrivateRoute
          exact
          path="/divida/refis/create"
          component={LayoutWrapper(RefisForm)}
        />
        <PrivateRoute
          exact
          path="/divida/refis/edit/:id"
          component={LayoutWrapper(RefisForm)}
        />
        <PrivateRoute
          exact
          path="/divida/BaixaRefis/edit/:id"
          component={LayoutWrapper(BaixaRefisEdit)}
        />
        <PrivateRoute
          exact
          path="/divida/BaixaRefis/create"
          component={LayoutWrapper(BaixaRefisForm)}
        />
        <PrivateRoute
          exact
          path="/divida/cadastro"
          component={LayoutWrapper(Cadastro)}
        />
        <PrivateRoute
          exact
          path="/divida/baixapagamento/edit/:id"
          component={LayoutWrapper(BaixaPagamentoEdit)}
        />
        <PrivateRoute
          exact
          path="/divida/baixapagamento/create"
          component={LayoutWrapper(BaixaPagamentoForm)}
        />
        <PrivateRoute
          exact
          path="/divida/processamento"
          component={LayoutWrapper(Processamento)}
        />
        <PrivateRoute
          exact
          path="/divida/refinanciamento/create"
          component={LayoutWrapper(RefinanciamentoForm)}
        />
        <PrivateRoute
          exact
          path="/divida/refinanciamento/restore"
          component={LayoutWrapper(RefinanciamentoRestore)}
        />

        <PrivateRoute
          exact
          path="/divida/relatorios"
          component={LayoutWrapper(Relatorios)}
        />
        <PrivateRoute
          exact
          path="/divida/pagamento/create"
          component={LayoutWrapper(PagamentoForm)}
        />
        <PrivateRoute
          exact
          path="/divida/refinanciamento/edit/:id"
          component={LayoutWrapper(RefinanciamentoForm)}
        />
        <PrivateRoute
          exact
          path="/divida/movimento"
          component={LayoutWrapper(MovimentoDivida)}
        />
        <PrivateRoute
          exact
          path="/notificacao/create"
          component={LayoutWrapper(Notificacao)}
        />
        <PrivateRoute
          exact
          path="/relatorios"
          component={LayoutWrapper(RelatoriosGerais)}
        />
        <PrivateRoute
          exact
          path="/usuario"
          component={LayoutWrapper(Usuario)}
        />
        <PrivateRoute
          exact
          path="/usuario/cadastro"
          component={LayoutWrapper(UserCadastro)}
        />
        <PrivateRoute
          exact
          path="/usuario/edit/:id"
          component={LayoutWrapper(UserCadastro)}
        />

        <PrivateRoute
          exact
          path="/protocolo/create"
          component={LayoutWrapper(ProtolocoForm)}
        />

        <PrivateRoute
          exact
          path="/protocolo"
          component={LayoutWrapper(Protocolos)}
        />

        <PrivateRoute
          exact
          path="/itbi/avaliar/:id"
          component={LayoutWrapper(AvaliacaoForm)}
        />
        <PrivateRoute
          exact
          path="/parcelaUnica/create"
          component={LayoutWrapper(ParcelaUnicaForm)}
        />
        <PrivateRoute
          exact
          path="/templateImovel"
          component={TemplateImovel}
        />

        <LoginRoute exact path="/login" component={LayoutWrapper(Login)} />
        <RedirectToLogin exact path="/" />
        <Route exact path="/menu" component={Main} />
        <Route exact path="/contribuinte/iptu" component={IptuContribuinte} />
        <Route exact path="/contribuinte/cnd" component={CND} />
        <Route exact path="/contribuinte/cniptu" component={CNIPTU} />
        <Route exact path="/contribuinte/cpen" component={CPEN} />
        <Route exact path="/contribuinte/alvara" component={Alvara} />
        <Route exact path="/validaCertidaoDeDebitos/:token" component={ValidacaoToken} />
        <Route exact path="/validateCertidaoDeImovel/:token" component={ValidacaoTokenIptu} />
        <Route exact path="/validateCertidaoPositiva/:token" component={ValidacaoTokenPositiva} />
        <Route exact path="/validaAlvara/:token" component={ValidacaoAlvara} />
        <Route exact path="*" component={NotFound} />
      </Switch >
    )
  }
}

export default Routes
