import React from "react"
import { withStyles } from "@material-ui/core"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import { alterarVencimento, imprimir } from "../../util/damavulso"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import {
  showNotification,
  mapErrors,
  showWaiting,
  hideWaiting
} from "../../util/Constants"
import moment from "moment"
import Warning from "@material-ui/icons/Warning"
import Done from "@material-ui/icons/Warning"
import AddIcon from "@material-ui/icons/Add"

const initialState = {
  errors: {},
  dam: [],
  id_dam: [],
  novo_vencimento: moment(new Date()).format("YYYY-MM-DD"),
  antigo_vencimento: new Date(),
  dam_itbi: []
}

class AlterarVencimento extends React.Component {
  constructor(props) {
    super(props)
    if (Object.keys(props).includes("dam")) {
      initialState.id_dam = { value: props.dam.id }
      initialState.antigo_vencimento = props.dam.vencimento
    }
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
    this.getDamById = this.getDamById.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.imprimirDam = this.imprimirDam.bind(this)
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Data de vencimento alterada realizada com sucesso",
          "success"
        )
        this.imprimirDam()
        if (this.props.fromModal) {
          this.props.handleClose("success")
        } else {
          this.setState(initialState)
        }
      }
    }
    alterarVencimento(
      this.props.damAvulso.id,
      this.state.novo_vencimento,
      null,
      response
    )
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      }
    )
  }

  imprimirDam() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()

        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          let error = JSON.parse(text)

          showNotification(
            "top",
            "center",
            `Ocorreram erros! ${error.message}`,
            "danger"
          )
        })
        reader.readAsText(error.response.data)

      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    imprimir(this.props.damAvulso.id, response)
  }

  getDamById(id) {
    return this.state.dam_itbi.filter(item => {
      return id === item.id
    })[0]
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Alteração de vencimento
                </h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="antigo_vencimento"
                  label="Data do Vencimento"
                  fullWidth
                  type="date"
                  disabled
                  value={this.props.damAvulso.vencimento}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={moment(this.props.damAvulso.vencimento).format(
                    "YYYY-MM-DD"
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="novo_vencimento"
                  label="Nova Data do Vencimento"
                  fullWidth
                  type="date"
                  error={this.state.errors.vencimento}
                  helperText={this.state.errors.vencimento}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={this.state.novo_vencimento}
                />
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={(this.state.lancadoNaDivida || this.state.pago) ? 6 : 2}>
                <Button
                  block
                  color={this.state.pago ? "success" : (this.state.lancadoNaDivida ? "danger" : "info")}
                  round
                  disabled={this.state.pago || this.state.lancadoNaDivida}
                  onClick={this.handleSave}
                >
                  {(this.state.lancadoNaDivida || this.state.pago) ? (
                    this.state.pago ? (<div>
                      <Done /> O DAM Selecionado já está pago
                      </div>
                    ) : (<div>
                      <div>
                        < Warning /> O DAM Selecionado foi lançado na dívida
                      </div>
                      <div> Código da dívida ativa: {this.state.divida_id}</div>
                    </ div >)
                  ) : (
                      <div>
                        <AddIcon /> Alterar
                    </div>
                    )}
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(AlterarVencimento)
